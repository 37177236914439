
// menu
"use strict";

(function() {
  var MenuToggler = $(".header--hamburger").click(function(e) {
    $(this).toggleClass("__js_isActive");
    $(this).parents().find(".header").toggleClass("__js_isActive");
  });
  $(".header").on("mouseleave", function() {
    $(this)
        .parents().find(".header").removeClass("__js_isActive");
  });
  $(".header li").on("click", function() {
    $(this)
        .parents().find(".header").removeClass("__js_isActive");
  });
})();


//dodanie klasy do header po scrollu

var $D = $(document).scrollTop();
var checkIfAddBg = function(){
    
  if ($D < 100) {
    $('header.header').removeClass('__jsBg');
  } else {
    $('header.header').addClass('__jsBg');
  }   
};

checkIfAddBg();

$(document).scroll(function() {
    $D = $(document).scrollTop();
    checkIfAddBg();
});