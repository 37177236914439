function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  
  (function() {
    var sondaBodies = document.querySelectorAll('.sonda--body');
  
    var generateBars = function(radios, answers)  {
        var sum = 0;
        
        radios.forEach(function(el){
            sum += parseInt(el.getAttribute('data-count'));
        });
        radios.forEach(function(el){
            var answerRow = document.createElement("div"),
                txtWrapper = document.createElement("div"),
                percentageWrapper = document.createElement("div"),
                valueWrapper = document.createElement("div"),
                answerBar = document.createElement("div"),
                percent = Math.round(Number(el.dataset.count) / sum * 100);
            answerBar.classList.add('sonda--bar');
            percentageWrapper.classList.add('sonda--percentage');
            valueWrapper.classList.add('sonda--value');
            valueWrapper.innerHTML = percent + '%';
            percentageWrapper.appendChild(valueWrapper);
            //percentageWrapper.innerHTML = percent + '%';
            percentageWrapper.appendChild(answerBar);
            
            txtWrapper.innerHTML = el.parentNode.textContent;
            txtWrapper.classList.add('sonda--txt');
            answerRow.classList.add('sonda--row');
            answerRow.appendChild(txtWrapper);
            answerRow.appendChild(percentageWrapper);
            answers.appendChild(answerRow);
            
            answers.classList.add('__js_visible');
            setTimeout(function() {
                answerBar.style.width = percent + 10 + '%';
                answerBar.style.fontSize = '1.3rem';
                answerBar.style.paddingLeft = '10px';
            }, 320);
            
        });
    };
    
    sondaBodies.forEach(function(sondaBody) {
        var answers = sondaBody.nextElementSibling;
        var radios = sondaBody.querySelectorAll('[name="sonda"]');
        var mf = function() {
            
            if (this.parentNode.parentNode.dataset.send) return;
            
            sondaBody.classList.add('__js_hidden');
            
            var xmlhttp = new XMLHttpRequest();
                    
            var url = sondaBody.querySelector('.sonda--form').getAttribute('action');

            var params = JSON.stringify({
               'value': this.value,
               'id': sondaBody.getAttribute('data-id')
            });

            xmlhttp.open("POST",  url, false);
            xmlhttp.send(params);

            this.dataset.count = Number(this.dataset.count) + 1;

            generateBars(radios, answers);
            this.parentNode.parentNode.dataset.send = true;
            var art = this.closest('.__js_stats_wrapper');
            var artId = art.dataset.id;
            var q = this.closest('.sonda--answers');
            var qName = q.dataset.question;
            var answerNumber = this.value;
            
            //sendStats('sonda','click-NUMER_ODPOWIEDZI', 'SLUG_ARTYKUŁU / TREŚĆ_PYTANIA')
            sendStats('sonda', 'click-' + answerNumber, artId + '/' + qName);
        };
        
        radios.forEach(function(el) {
            el.addEventListener('change', mf);
            
        });
    });
    
  })();
  