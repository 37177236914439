(function(){
      $.fn.runSlider = function(interval){
          return $('.hotpoints--slider').each(function(){
          var current = 0,
              interval = interval||3000,
              $slider = $(this)
                  .bind('mouseenter', function(){ 
                      if (ts) {
                          clearTimeout(ts);
                          ts = null;
                      }
                  })
                  .bind('mouseleave', function(){ 
                      if (!ts){
                         ts = setTimeout(rotate, interval); 
                      }
                  }),                
              ts = null,
              showSlide = function(n){
                  current = n;
                  $slider.find('.hotpoints--page')
                      .animate({opacity: 0}, 120)
                      .removeClass('isActive')
                      .eq(current)
                      .addClass('isActive')
                      .animate({opacity: 1 }, 220);
                  $slider.find('.hotpoints--range')
                      .removeClass('isActive')
                      .eq(current)
                      .addClass('isActive');
              },
              rotate = function(){
                  current++;
                  if (current>=$slider.find('.hotpoints--page').length) current = 0;
                  showSlide(current);
                  ts = setTimeout(rotate, interval);
              };

          $slider.find('._js_switch').bind('click', function(){
              current = $(this).parent().index();
                
                var $arId = $(this).parents('.ajax-wrapper').data('id'),
                    $gId = $(this).parents('.hotpoints--slider').data('hotpoints'),
                    $acId = $(this).parent().find('.hotpoints--slider-img').attr('src');
              
               sendStats('hotpoint', 'click', $arId + ' / ' + $gId, $acId); 
              
              showSlide(current);
          });
          ts = setTimeout(rotate, interval);
      });
  };
})();

$('.slider').runSlider();