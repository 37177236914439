var sgPlayerTarget = $('#section-video');
var sgPlayer = new WP.player({
  url: $("#section-video-slider .owl-item.center .section-video--player-item").eq(0).data('url'),
  width: '100%',
  height: '100%',
  target: 'section-video--player',
  autoplay: false,
  hiderelated: true
});

var newPlayer = function(url, poster) {
   sgPlayer.destroy();
   sgPlayer = new WP.player({
      url: url,
      width: '100%',
      height: '100%',
      target: 'section-video--player',
      autoplay: false,
      hiderelated: true,
      screenshot: poster
    }); 
};
function changeClip(url, poster) {  
    sgPlayer.pause();
    newPlayer(url, poster);
}

sgPlayerTarget.find('.section-video--player-item').on("click", function () {
    changeClip($(this).data('url'), $(this).find('img').attr('src'));
});


$(".section-video .item").on("click", function() {

    sgPlayer.destroy();
    player = newPlayer($(this).attr("data-url"), i);
    
    var title = $(this).find('.owl-video-desc h4').text();
    var description = $(this).find('.owl-video-desc span').text();

    //$("#wideo .video-desc h4").text(title);
    //$("#wideo .video-desc span").text(description);

});


if ($(".section-video--player-item").length > 0) {

    $(".section-video--player-item").each(function() {

      var $this = $(this);
      var imgSrc;
      var title;

      if ($(this).attr("data-yt") === "true") {
        $.ajax({
          url: "https://noembed.com/embed?url=" + $(this).attr("data-url"),
          dataType: "json"
        }).done(function( data ) {
          imgSrc = data.thumbnail_url;
          title = data.title;
          $($this).find("img").attr("src", imgSrc );
          $($this).find("p").text( title );
        }).
        fail(function() {
          imgSrc = "http://hankook-2019.wpcdn.pl/img/static/tv-placeholder.jpg";
          title = "";
          $($this).find("img").attr("src", imgSrc );
          $($this).find("p").text( title );
        });
      } else {
        var url = "https://wideo.wp.pl/player/embed.json?mid=" + $(this).attr("data-mid") + '/secured';

        $.ajax({
          url: url,
        }).done(function( data ) {
          imgSrc = data.clip.screenshot;
          title = data.clip.title;
          $($this).find("img").attr("src", imgSrc );
          $($this).find("p").text( title );
        }).
        fail(function() {
          imgSrc = "http://hankook-2019.wpcdn.pl/img/static/tv-placeholder.jpg";
          title = "";
          $($this).find("img").attr("src", imgSrc );
          $($this).find("p").text( title );
        });
      }
    });
}
