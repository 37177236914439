//before after, suwak, ga360

var beforeAfterSections = $(".section--before-after").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do Suwaka: ' + $(this).parents('.section--before-after').data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
      statElementCollector[ga360id + '-' + $artID] = {
        id: ga360id,
        moduleName: $(this).find('.section--before-after-active').data('id'),
        viewedCount: 0,  
        itemCount:  2
      };  
    };
    $(this).statVievedSlide = [];
    //pozycje
    
    slidePositionVieved[ga360id + '-' + $artID] = {};
});

(function () {
  $.fn.ba = function () {
    return $(".section--before-after--after").each(function () {

      
    var fw = [],
        fb = [],

        isMobile = /mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Fennec/i.test(
          navigator.userAgent
        ),
        imgLeft = $(this).parents(".section--before-after-marker"),
        $t = $(this).parents(".section--before-after-active"),
        margin = $t.offset().left,
        //i = $t.parents(".section-wrapper").index(),
        x;

      $(window).resize(function () {
        $(imgLeft).width($(".section--before-after").width() / 2);
      });

      if (!isMobile) {

        $(this).on("mouseup", function (e) {
          $(this).removeClass("draggable");
        });

        $(window).on("mouseup", function (e) {
          $('.section--before-after--after').removeClass("draggable");
          $('.section--before-after-marker').removeClass("move");
        });

        $(this).on("mousedown", function (e) {
          $(this).addClass("draggable");
          $(this).parent().addClass("move");
          margin = $t.offset().left;

        });

        $('.section--before-after-active').on("mousemove", function (e) {
            var ga360id = $(this).parents(".section--before-after").attr("data-ga360-id");
            var $moduleName = $(this).parents(".section--before-after").attr("data-id");
            if(!ga360id) { console.log('%c dodać data-ga360-id do suwaka jesli mają iść staty!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
            
          if ($('.section--before-after--after').hasClass("draggable")) {

            if (e.clientX <= margin) {
              x = margin;
            } else if (e.clientX > margin + $t.outerWidth()) {
              x = margin + $t.outerWidth();
            } else {
              x = e.clientX;
            }
            margin = $t.offset().left;
            imgLeft = $('.section--before-after-marker.move');
            $(imgLeft).width(x - margin);

            //stats start
            
            var percentage = (x - margin) / $t.width();
              
            if (!fw[i] && percentage > 0.8) {
              fw[i] = true;
              fb[i] = false;
              sendStats("before-after","view",$t.parents(".section-wrapper").attr("data-id") + " / " + $t.attr("data-id"), $t.find(".section--before-after-wraper img").attr("src"));
              console.log('!!!!!' + ga360id + ', ' + slidePositionVieved);
              // obejrzanyy pierwszy

              slidePositionVieved[ ga360id + '-' + $artID ][ 1 ] = 1;
              statElementCollector[ ga360id + '-' + $artID ]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id + '-' + $artID ]).length;
             
              WP.pwp.customAction({"ga": {"event": "content_interaction", "eventCategory": ga360id, "moduleName": $moduleName ,"eventAction": deviceTouch?"swipe":"show"}});
                
            }
            if (!fb[i] && percentage < 0.2) {
              fb[i] = true;
              fw[i] = false;
              sendStats("before-after", "view", $t.parents(".section-wrapper").attr("data-id") + " / " + $t.attr("data-id"), $t.find(".section--before-after--before img").attr("src"));
              console.log('!!!!!' + ga360id + ', ' + slidePositionVieved);
              //obejrzany drugi
              slidePositionVieved[ ga360id + '-' + $artID ][ 2 ] = 1;
              statElementCollector[ ga360id + '-' + $artID ]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id + '-' + $artID ]).length;
                
              WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $moduleName ,"eventAction": deviceTouch?"swipe":"show"}});
            }

            // stats end

          } else {
            return;
          }
        });
      } else {

        $(this).on("touchend", function (e) {
          $(this).removeClass("draggable");
          $t.removeClass("before-after__active");
        });

        $(this).on("touchstart", function (e) {
          $(this).addClass("draggable");
          $t.addClass("before-after__active");
        });

        $(this).on("touchmove", function (e) {
          var touch = event.touches[0];
          var xm = touch.pageX;
          if ($(this).hasClass("draggable")) {
            if (xm < margin) {
              x = margin;
            } else if (xm > margin + $t.outerWidth()) {
              x = margin + $t.outerWidth();
            } else {
              x = xm;
            }
            $(imgLeft).width(x - margin);

            //stats start

            var percentage = (x - margin) / $t.width();
            var ga360Id = $t.parents('.section--before-after').attr("data-ga360-id");
            var $moduleName = $t.parents('.section--before-after').attr("data-id");
              
            if(!ga360id) {console.log('%c dodać data-ga360-id do slidera jesli mają iśc staty!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
              
            if (!fw[i] && percentage > 0.8) {
                
              fw[i] = true;
              fb[i] = false;
              sendStats( "before-after", "view", $t.parents(".section-wrapper").attr("data-id") + " / " + $t.attr("data-id"), $t.find(".section--before-after-wraper img").attr("src"));
            
              WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $moduleName ,"eventAction": deviceTouch?"swipe":"show"}});
            }
            if (!fb[i] && percentage < 0.2) {
              fb[i] = true;
              fw[i] = false;
              sendStats( "before-after", "view", $t.parents(".section-wrapper").attr("data-id") + " / " + $t.attr("data-id"),$t.find(".section--before-after--before img").attr("src"));
              
              WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $moduleName ,"eventAction": deviceTouch?"swipe":"show"}});
                
            }

            // stats end

          } else {
            return;
          }
        });
      }

    });
  };
})();
$(".section--before-after--after").ba();

