//Akordeon, ga360
'use strict';

var accordionSections = $(".gallery__accordion").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do Akordeonu: ' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
      statElementCollector[ga360id + '-' + $artID] = {
        id: ga360id,
        moduleName: $(this).data('id'),
        viewedCount: 0,
        itemCount:  $(this).find('.accordion__element').length
      };
    };
    $(this).statVievedSlide = [];
    //pozycje

    slidePositionVieved[ga360id + '-' + $artID] = {};
});



(function () {

    $('.accordion__element').on('mouseenter', function () {
        if (isScrolling) return;

        if(!$(this).hasClass('accordion__element--hover')) {
          var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
              $accID = $(this).parent().data('id'),
              $elId = $(this).index() + 1,
              $imgID = $(this).find('img').data('src');
          var ga360id = $(this).parents(".gallery__accordion").data('ga360-id');

          var activeItem = $(this).index();

          //do tablicy wpadają obejrzane (ga360)
          slidePositionVieved[ ga360id + '-' + $artID ][ activeItem ] = 1;
          statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id + '-' + $artID ]).length;

          if (!isScrolligFlag) {
               WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $accID ,"eventAction": deviceTouch?"tap":"hover"}});

              sendStats(
                  "gallery-accordion",
                  "hover-" + $elId,
                  $artID + " / " + $accID,
                  $imgID
              );
          }

          $('.accordion__element').removeClass('accordion__element--hover');
          $(this).addClass('accordion__element--hover');
        }
    });

})();
