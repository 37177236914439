//patchwork hover ga360

'use strict';


var patchworkSections = $(".patchwork--deco").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');

    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do patchworka: ' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
        statElementCollector[ga360id + '-' + $artID] = {
            id: ga360id,
            moduleName: $(this).data('id'),
            viewedCount: 0,
            itemCount:  $(this).find('.patchwork--slot').length
        };
    };
    $(this).statVievedSlide = [];
    //pozycje

    slidePositionVieved[ga360id] = {};
});

var patchworkSectionsH = $(".patchwork-hover--wrapper").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do Galerii patchwork(2x1): ' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
      statElementCollector[ga360id + '-' + $artID] = {
        id: ga360id,
        moduleName: $(this).data('id'),
        viewedCount: 0,
        itemCount:  $(this).find('.patchwork-hover--slot').length
      };
    };
    $(this).statVievedSlide = [];
    //pozycje

    slidePositionVieved[ga360id] = {};
});


(function() {

    $('.__Small .patchwork--slot').mouseenter(function() {

        if (isScrolling) return;
        var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
            $galID = $(this).parents('.patchwork--deco').data('id'),
            $imgID = $(this).find('.patchwork--slot-img').attr('src'),
            ga360id = $(this).parents('.patchwork--deco').data('ga360-id');
            if(!ga360id) {console.log('%c dodać ga360-id do patchworka!' + $galID + 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
        //console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);
        var activeItem = $(this).index();
        slidePositionVieved[ ga360id ][ activeItem ] = 1;
        statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id ]).length;
        sendStats('gallery', 'hover', $artID + ' / ' + $galID, $imgID);

        if (!isScrolligFlag) {
             WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $galID ,"eventAction": deviceTouch?"tap":"hover"}});
        }

    });

})();

(function() {

    $('.patchwork-hover--slot').mouseenter(function() {

        if (isScrolling) return;

        var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
            $galID = $(this).parents('.patchwork-hover--wrapper').data('id'),
            $imgID = $(this).find('.patchwork-hover--slot-img').attr('src'),
            ga360id = $(this).parents('.patchwork-hover--wrapper').data('ga360-id');
            if(!ga360id) {console.log('%c dodać data-360-id do slidera!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
        //console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);
        var activeItem = $(this).index();
        slidePositionVieved[ ga360id ][ activeItem ] = 1;
        statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id ]).length;
        sendStats('gallery', 'hover', $artID + ' / ' + $galID, $imgID);

        if (!isScrolligFlag) {
             WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $galID ,"eventAction": deviceTouch?"tap":"hover"}});
        }
    });

})();


//patchwork na całą szerokość na 7 zdjęć
$.fn.zoomer = function() {
  return this.each(function() {
    var $this = $(this),
      $lista = $this.find(".patchwork--slot"),
      count = $lista.length,
      $arId = $(this).parents(".__js_stats_wrapper").data("id"),
      $gId = $(this).parents(".patchwork--deco").data("id"),
      ga360id = $(this).parents(".patchwork--deco").data("ga360-id"),
      $acId,
      $slotId,
      open = function(n, artID, galID, ga360ID) {
        if ($(".patchwork--lightbox").length) {
          goTo(n);
        } else {
          var tmp = document.querySelector("template.lightbox"),
            cln = tmp.content.cloneNode(true),
            $lb = $('<div class="patchwork--lightbox">')
              .append(cln)
              .appendTo("body");
          $lb.find(".patchwork--close").bind("click", function() {
            $(".patchwork--deco.__js_active").removeClass("__js_active");
            $("body")
              .find("div.patchwork--lightbox")
              .remove();
          });
          var $next = $lb.find(".patchwork--next"),
            $prev = $lb.find(".patchwork--prev"),
            $foto = $lb.find(".patchwork--big"),
            $text = $lb.find(".patchwork--desc"),  
            active = n,
            goTo = function(n) {
              active = n;

              $foto.attr("src", $lista.eq(n).data("big"));
                
              $text.html(
                $lista
                  .eq(n)
                  .find(".patchwork--desc")
                  .html()
              );

              $acId = $lista.eq(n).data("big");
              $slotId = $lista.eq(n).data("order");
              $gId = $(".patchwork--deco.__js_active").data("gallery");

              sendStats("gallery","click-big-" + (n + 1),$arId + " / " + $gId);

              if (!isScrolligFlag) {
                WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360ID, "moduleName": galID ,"eventAction": deviceTouch?"tap":"click"}});
              }

            },
            goNext = function() {
              active++;
              if (active > count - 1) active = 0;
              goTo(active);
            },
            goPrev = function() {
              active--;
              if (active < 0) active = count - 1;
              goTo(active);
            };
          $next
            .bind("mousedown", function(e) {
              e.preventDefault();
            })
            .bind("click", goNext);
          $prev
            .bind("mousedown", function(e) {
              e.preventDefault();
            })
            .bind("click", goPrev);
          goTo(active);
        }
      };

    if(!ga360id) {console.log('%c dodać data-ga360-id do slidera!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
    //init asdasd
    $this.find(".patchwork--slot").bind("click", function() {

      $(this)
        .parents(".patchwork--deco")
        .addClass("__js_active");

      var $arId = $(this).parents(".__js_stats_wrapper").data("id"),
        $gId = $(this).parents(".patchwork--deco").data("id"),
        ga360id = $(this).parents(".patchwork--deco").data("ga360-id"),
        $acId = $(this).parent().find(".patchwork--slot-img").attr("src"),
        $slotId = $(this).data("order");
        
        console.log($gId +  ', aid: '  + $arId);
      if(!ga360id) { console.log('%c dodać data-ga360-id do slidera!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
      sendStats("gallery", "click-small-" + $slotId, $arId + " / " + $gId);

      if (!isScrolligFlag) {
        WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName":$gId, "eventAction": deviceTouch?"tap":"click"}});
      }
      open($(this).index(), $arId, $gId, ga360id);
    });
  });
};

$(".patchwork--deco:not('.__Small')").zoomer();


//patchwork
$.fn.zoomer2 = function() {
  return this.each(function() {
    var $this = $(this),
      $lista = $this.find(".patchwork--slot"),
      count = $lista.length,
      $arId = $(this).parents(".__js_stats_wrapper").data("id"),
      $gId = $(this).parents(".patchwork--deco").data("gallery"),
      ga360id = $(this).parents(".patchwork--deco").data("ga360-id"),
      $acId,
      $slotId,
      open = function(n) {
        if ($(".patchwork--lightbox").length) {
          goTo(n);
        } else {
          var tmp = document.querySelector("template.lightbox"),
            cln = tmp.content.cloneNode(true),
            $lb = $('<div class="patchwork--lightbox">').append(cln).appendTo("body");
          $lb.find(".patchwork--close").bind("click", function() {
            $(".patchwork--deco.__js_active").removeClass("__js_active");
            $("body").find("div.patchwork--lightbox").remove();
          });
          var $next = $lb.find(".patchwork--next"),
            $prev = $lb.find(".patchwork--prev"),
            $foto = $lb.find(".patchwork--big"),
            $text = $lb.find(".patchwork--desc"),
            active = n,
            goTo = function(n) {
              active = n;

              //console.log($lista.eq(n).data('big'), $lista.eq(n).find('.patchwork--desc').html());

              $foto.attr("src", $lista.eq(n).data("big"));
              $text.html(
                $lista.eq(n).find(".patchwork--desc").html()
              );

              $acId = $lista.eq(n).data("big");
              $slotId = $lista.eq(n).data("order");
              $gId = $(".patchwork--deco.__js_active").data("gallery");

              sendStats("gallery","click-big-" + $slotId,$arId + " / " + $gId);
              if (!isScrolligFlag) {
                WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName":$gId, "eventAction": deviceTouch?"tap":"click"}});
              }
            },
            goNext = function() {
              active++;
              if (active > count - 1) active = 0;
              goTo(active);
            },
            goPrev = function() {
              active--;
              if (active < 0) active = count - 1;
              goTo(active);
            };
          $next
            .bind("mousedown", function(e) {
              e.preventDefault();
            })
            .bind("click", goNext);
          $prev
            .bind("mousedown", function(e) {
              e.preventDefault();
            })
            .bind("click", goPrev);
          goTo(active);
        }
      };
      if(!ga360id) {console.log('%c dodać data-ga360-id do slidera!', 'background: #3b3b3b; padding: 3px; color: #beeb2f');}

    //init asdasd
    $this.find(".patchwork--slot").bind("click", function() {

      $(this).parents(".patchwork--deco").addClass("__js_active");

      var $arId = $(this).parents(".__js_stats_wrapper").data("id"),
        $gId = $(this).parents(".patchwork--deco").data("gallery"),
        ga360id = $(this).parents(".patchwork--deco").data("ga360-id"),
        $slotId = $(this).data("order");

      sendStats("gallery", "click-small-" + $slotId, $arId + " / " + $gId);

      if (!isScrolligFlag) {
        WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName":$gId, "eventAction": deviceTouch?"tap":"click"}});
      }
      open($(this).index());

    });
  });
};


var mq = matchMedia("(max-width: 700px)");
function handleMediaChange(event) {
    if(event && event.matches) //pamiętać o dodatkowym warunku na zdefiniowany event!
        $(".patchwork--deco.__Small").zoomer2();
    else {
        $(".patchwork--deco.__Small").unbind().zoomer2();
    }
}
mq.onchange = handleMediaChange;
